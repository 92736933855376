<template>
    <div>
        <NavOnlineAdmission></NavOnlineAdmission>
        <b-container>
            <b-row class="mt-5 justify-content-center">

                <b-modal v-model="isModalVisible"  hide-footer>

                    <ul>
                        <li v-for="(error, index) in errors" :key="index" class="text-danger">{{ error }}</li>
                    </ul>

                    <h5 v-if="messsaage" class="text-danger">{{ messsaage }}</h5>
                </b-modal>

                <SideNavOnlineAdmission></SideNavOnlineAdmission>
                <b-col cols="12" md="5" class="">
                    <b-card>
                        <b-card-body>
                            <b-card-text class="text-center">
                                <h3>Form Fees: <b>{{ formFees }}</b></h3>
                                <b-button variant="primary" class="mt-2" @click="payNow">Pay Now</b-button>
                            </b-card-text>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
  
<script>
import {
    BCard, BCardText, BButton, BContainer, BRow, BCol, BCardBody
} from 'bootstrap-vue'

import NavOnlineAdmission from '@/views/components/nav/NavOnlineAdmission.vue'
import SideNavOnlineAdmission from '@/views/components/nav/SideNavOnlineAdmission.vue';
import axios from 'axios';


export default {
    components: {
        BCard,
        BCardText,
        BButton,
        BContainer,
        BRow,
        BCol,
        NavOnlineAdmission,
        SideNavOnlineAdmission,
        BCardBody
    },
    data() {
        return {
            formFees: null,
            // forms: [],
            institute: this.$route.params.institute,
            errors: null,
            isModalVisible: false,
            messsaage: null,
        }
    },
    computed: {
        // applicantId() {
        //     return this.$store.state.onlineAdmission.applicantId;
        //     // return this.$store.onlineAdmission.getters.getApplicantId;
        // }
    },
    directives: {

    },
    props: {

    },

    created() {
        this.getSingleForm();
    },

    methods: {
        getSingleForm() {
            let institute = localStorage.getItem('institute') || null;
            let formId = localStorage.getItem('formId') || null;
            axios.get(process.env.VUE_APP_API_URL + `get-single-form/${institute}/${formId}`)
                .then(response => {
                    this.formFees = response.data.data.application_fee;
                    // this.$store.dispatch('onlineAdmission/singleForm', singleForm);
                    // console.log(response.data.data);
                })
                .catch(error => {
                    // console.log(error);
                });
        },

        payNow() {
            let applicantId = localStorage.getItem('applicantId') || null;
            axios.post(process.env.VUE_APP_API_URL + `check-online-form-submit?applicant_id=${applicantId}`)
                .then(response => {
                    this.isModalVisible = false;
                    this.errors = null;
                    this.messsaage = null;
                    this.goToPayment();
                })
                .catch(error => {
                    this.isModalVisible = true;
                    this.errors = error.response.data.errors;
                    this.messsaage = error.response.data.message;
                });
        },

        goToPayment() {
            let applicantId = localStorage.getItem('applicantId') || null;
            axios.post(process.env.VUE_APP_API_URL + `online-admission/payment/create-session?applicant_id=${applicantId}`)
                .then(response => {
                    this.removeData();
                    window.location.href = response.data.redirect_url;
                })
                .catch(error => {
                    // console.log(error);
                });
        },

        removeData() {
            console.log('Data is being removed');
            // localStorage.removeItem('institute');
            // localStorage.removeItem('formId');
            localStorage.removeItem('applicantId');
            localStorage.removeItem('temporaryId');
            this.$router.push({ path: `/admission/${this.institute}`, });
            // location.reload();
            // location.reload();
        },
    }
}
</script>
<style scoped></style>
  